<template>
  <div class="wrapper">
    <div class="wrapper__header">
      <div class="header__title">Отправить команду</div>
      <div class="header__icon">
        <close-icon
          class="header__close"
          @click.native="$emit('close-create-commands')"
        />
      </div>
    </div>
    <div class="wrapper__body">
      <div class="inputs-side">
        <div class="inputs__select">
          <span class="name">Выберите объект</span>
          <el-select v-model="form.units" multiple collapse-tags placeholder="Выберите объект">
            <el-option
              v-for="item in filterUnitsByType"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </div>

        <div class="inputs__select">
          <span class="name">Команда234</span>
          <el-select
            v-model="form.commandsTemplate"
            placeholder="Выберите команду"
          >
            <el-option
              v-for="item in commandsTemplate"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </div>

        <div
          v-for="(item, index) in params"
          :key="index"
          class="inputs__select"
        >
          <span class="name">Параметр {{ index + 1 }}</span>
          <el-input v-model="form.value[index]" :placeholder="item" />
        </div>
      </div>
      <div class="button-side">
        <button class="primary" @click="sendCommand">Отправить</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import CloseIcon from '@/components/commands/assets/icons/CloseIcon.vue'
import PlusIcon from '@/components/commands/assets/icons/PlusIcon.vue'
import SearchIcon from '@/components/commands/assets/icons/SearchIcon.vue'

export default {
  name: 'CreateCommands',
  components: {
    CloseIcon,
    PlusIcon,
    SearchIcon,
  },

  props: {
    unit: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isAddForm: false,
      form: {
        terminalType: '',
        commandsTemplate: '',
        value: [],
        units: [],
      },
      params: [],
    }
  },

  mounted() {
    this.getCommandsTemplate()
    this.form.units.push(this.unit.id);
  },

  computed: {
    ...mapState('dictionary', {
      terminalTypes: (state) => state.terminal_type,
    }),

    ...mapState('login', {
      me: (state) => state.me,
    }),

    ...mapGetters({
      commandsTemplate: 'commands/commandsTemplate',
      units: 'units/units',
    }),

    filterUnitsByType() {
      return this.units.filter(el => el.terminal_type.key === this.unit.terminal_type.key)
    },
  },

  watch: {
    'form.commandsTemplate'(newVal) {
      if (newVal) {
        console.log('newVal');
        this.params = []
        this.form.value = []
        this.commandsTemplate.forEach(el => {
          if (el.params.length > 0 && el.id === newVal) {
            this.params.push(el.params)
          }
        })
      }
    },
  },

  methods: {
    ...mapActions({
      getCommandsTemplate: 'commands/getCommandTemplate',
      send: 'commands/sendCommand',
    }),

    async sendCommand() {
      if (!this.form.commandsTemplate || this.form.units.length === 0) {
        return this.$notify.error({
          duration: 5000,
          title: this.$t('error') + ' \u{1f62a}',
          message: 'Заполните поле "Выберите объект" и "Команда"'
        })
      }

      if (this.params.length > 0 && this.form.value.length === 0) {
        console.log('here');
        return this.$notify.error({
          duration: 5000,
          title: this.$t('error') + ' \u{1f62a}',
          message: 'Заполните поле "Параметр"'
        })
      }

      let keys = [...this.params.flat()];
      let paramsArray = this.form.value.map((value, index) => {
        return {key: keys[index], value: value}
      });

      const filterParamsArray = paramsArray.filter(el => el !== null)

      const imeisArr = []
      this.form.units.filter((el, index) => {
        this.units.forEach(item => {
          if (el === item.id) {
            imeisArr.push(item.imei)
          }
        })
      })

      const params = {
        company_id: this.me.active_company_id,
        imeis: imeisArr,
        template: {
          id: this.form.commandsTemplate,
          params_values: filterParamsArray ? filterParamsArray : []
        }
      }

      const data = await this.send(params)

      if (data.status !== 200) {
        return this.$notify.error({
          duration: 5000,
          title: this.$t('error') + ' \u{1f62a}',
          message: data.data.message
        })
      }

      this.$emit('close-create-commands')
    },
  },
}
</script>

<style lang="stylus" scoped>
.primary
  padding 11.5px 44.5px
  background-color #5477A9
  font-size 14px
  line-height 16px
  font-weight 600
  color #fff
  border-radius 5px
  border none
  outline none
  transition all 0.3s
  &:hover
    background-color #3C5E8F

.wrapper
  position absolute
  top 1.25rem
  bottom 1.25rem
  left 1.25rem
  width 500px
  z-index 10
  background-color #fff

  &__header
    display flex
    align-items center
    justify-content space-between
    padding 25px

    .header
      &__title
        line-height 25px
        font-weight 700
        font-size 20px
        color #5477A9
        font-family Source Sans Pro
      &__close
        cursor pointer
      &__history
        margin-right 30px
        cursor pointer
  &__body
    display grid
    grid-template-columns 1fr 1fr
    grid-template-rows 1fr 1fr
    column-gap 25px
    grid-template-areas "inputs-side button-side" \
      "table-side table-side"
    font-family Source Sans Pro
    .inputs
      &__select
        margin-bottom 24px
        .name
          display inline-block
          margin-bottom 9px
          font-size 14px
          line-height 19px
          font-weight 600
          color #91B4E7
        .el-select
          width 255px

.inputs-side
  grid-area inputs-side
  padding-left 25px
.button-side
  grid-area button-side
  padding-right 25px
  margin-top 30px
  text-align right
.table-side
  grid-area table-side
  .table-head
    display grid
    grid-template-columns 1fr 1fr
    grid-template-rows auto
    column-gap 23px
    grid-template-areas "table-head__title table-head__icon" \
      "table-head__search table-head__search" \
      "table-head__select table-head__button"
    padding 19px 25px
    transition all 0.3s
    &--isOpen
      grid-template-rows 35px 1fr 35px
      background-color #F7FBFF
    &__title
      font-weight 600
      font-size 14px
      color #91B4E7
      font-family Source Sans Pro
      grid-area table-head__title
    &__icon
      text-align right
      grid-area table-head__icon
      .icon
        cursor pointer
        transition all 0.3s
        &--isOpen
          transform rotate(45deg)
    &__search
      position relative
      margin-bottom 20px
      grid-area table-head__search
      .search-icon
        position absolute
        right 0px
        top 50%
        transform translate(-50%, -50%)
    &__select
      grid-area table-head__select
      width 255px
    &__button
      grid-area table-head__button

.table-tr
  display flex
  align-items center
  justify-content space-between
  padding 0 25px
  margin-top 25px
  &__name
    font-size 16px
    font-weight 700
    line-height 19px
    color #5477A9
    font-family Source Sans Pro
  &__icon
    cursor pointer
</style>
